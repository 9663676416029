/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext } from "react";
import { Box, Grid, Heading, Paragraph, Text } from "grommet";

import Trail from "../_shared_/trail";
import SalaryIllustration from "./components/salary.illustration";
import HomeIllustration from "../../components/pages/components/home.illustration";
import { Buttons } from "../../styles/components/button-effect";
import FadeIn from "../_shared_/fade-in";
import { SpringLink } from "../_shared_/transition";
import ScrollContext from "../../context/scroll-context";
import { mainBlack } from "../../styles/constants";
import styled from "styled-components"
import Cloud from "../../images/cloud.svg"

const CloudBox = styled.div`
  position: absolute;
  width: 80%;
  top: -10%;
  right: 50%;
  z-index: 0;
  transform: rotate(180deg);
  opacity: 0.5;
`

const SalaryLayout: React.FC<{ size: string }> = ({ size }) => {
  const context = useContext(ScrollContext);
  const desktop = size !== `small`;

  return (
    <Grid columns={desktop ? [`50%`, `50%`] : [`100%`]} rows="fit" gap="small">
      <Box>
        <Heading size="medium" margin={{ bottom: `8px` }}>
          {desktop && <Trail text="Mzdy a personalistika" />}
          {!desktop && `Mzdy a personalistika`}
        </Heading>
        <Paragraph margin={{ top: `0px`, bottom: `30px` }} size="large" style={{ opacity: 0.6 }} color={mainBlack}>
          pre každého
        </Paragraph>
        <FadeIn>
          <Text margin="0" size="large" weight="bold">
            Mzdy
          </Text>
          <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
            Služby spracovania miezd, komunikácie s verejnými inštitúciami a poskytovanie odborných rád v personálnej oblasti môžete mať u
            nás už od 10 €.
          </Paragraph>
          <Text margin="0" size="large" weight="bold">
            Personalistika
          </Text>
          <Paragraph margin={{ bottom: `15px`, top: `5px` }} fill size="medium">
            Správa pracovných zmlúv, archivácia dokumentov, evidencia neprítomností, ročné zúčtovanie preddavkov na daň zo závislej
            činnosti. S týmto všetkým Vám naši mzdoví účtovníci radi pomôžu.
          </Paragraph>
          <Buttons mt={12}>
            <SpringLink to="/" onClick={(): void => context.handleSetScrollTo(`contact`)}>
              Mám záujem
            </SpringLink>
          </Buttons>
        </FadeIn>
      </Box>
      <SalaryIllustration size={size} />
      <CloudBox>
        <Cloud />
      </CloudBox>
    </Grid>
  );
};

export default SalaryLayout;
