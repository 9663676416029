/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import { Box, ResponsiveContext } from "grommet"
import FooterLayout from "../components/_shared_/footer"
import { Transition } from "../components/_shared_/transition"
import HeaderLayout from "../components/_shared_/header"
import SalaryLayout from "../components/sub/salary"
import { Container } from "../styles/components/container"
import GrommetWrapper from "../components/_shared_/grommet"
import SEO from "../components/_shared_/seo"
import QA from "../components/sub/components/qa"
import { Questions } from "../types/interface"
import Cookies from "../components/_shared_/cookies"

const salaryQA: Questions[] = [
  {
    question: `Aké povinnosti má zamestnávateľ ?`,
    answer: `Zoznam povinností zamestnávateľa je veľmi dlhý, naši skúsení mzdári sú pripravení Vás na ne vždy upozorniť.`,
  },
  {
    question: `Sú výpočet miezd a evidencia zamestnancov náročné ?`,
    answer: `Niekedy sa pri spracúvaní miezd zapotí aj skúsená ekonómka. Odovzdaním mzdovej agendy do rúk profesionálom si ušetríte mnoho starostí.`,
  },
  {
    question: `Prečo by ste sa mali rozhodnúť pre nás ?`,
    answer: `Pretože ručíme za kvalitu našich služieb, spoluprácu zakladáme na vzájomnej dôvere a neustále napredujeme, aby sme vždy uspokojili požiadavky našich klientov.`,
  },
]

const Salary: React.FunctionComponent = () => {
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <GrommetWrapper>
      <SEO
        title="Mzdy a personalistika | Vyskúšajte 1. mesiac zadarmo | SlovAcc"
        description="Mzdy: Ponúkame Vám služby spracovania miezd, komunikácie s verejnými inštitúciami, zasielania mesačných výkazov a poskytovanie odborných rád v personálnej oblasti. Personalistika: Prihlasovanie a odhlasovanie zamestnancov, správa pracovných zmlúv, archivácia dokumentov alebo ročné zúčtovanie preddavkov na daň. S týmto všetkým Vám vieme pomôcť."
      />
      <Cookies />
      <ResponsiveContext.Consumer>
        {(size: string): JSX.Element => (
          <>
            <HeaderLayout size={size} />
            <Transition>
              <Container>
                <Box
                  justify="center"
                  height={size !== `small` ? `100vh` : `100%`}
                  margin={{ top: size !== `small` ? `0px` : `93px`, bottom: size !== `small` ? `0px` : `93px` }}
                >
                  <SalaryLayout size={size} />
                </Box>
                <QA filterOutId={2} size={size} data={salaryQA} />
              </Container>
              <Container minHeight="auto" fluid>
                <FooterLayout size={size} />
              </Container>
            </Transition>
          </>
        )}
      </ResponsiveContext.Consumer>
    </GrommetWrapper>
  )
}

export default Salary
